import * as moment from 'moment';

const setFontMixin = {
	methods : {
		updateFlipCountdown() {
			if (this.jsonConfig.countdown !== undefined) {
				let day      = moment().isoWeekday();
				let deadline = this.jsonConfig.countdown.deadline;

				if (this.jsonConfig.countdown.auto_date !== null
					&& this.jsonConfig.countdown.auto_date !== undefined
					&& this.jsonConfig.countdown.auto_date !== '') {
					const isAuto   = this.jsonConfig.countdown.auto_date;
					let difference = day - isAuto;
					if (difference <= 0) {
						return moment()
							.endOf('day')
							.add(Math.abs(difference), 'd')
							.format('YYYY-MM-DD HH:mm:ss');
					}

					if (difference >= 1) {
						return moment()
							.endOf('day')
							.add(Math.abs(difference - 7), 'd')
							.format('YYYY-MM-DD HH:mm:ss');
					}
				}
				return moment(deadline).format('YYYY-MM-DD HH:mm:ss');
			}
		},
	}
};

export default setFontMixin;
