const motorPriveMixin = {
	methods : {
		initMotorPrive() {
			(function (w, d, s, o, f, js, fjs, mpd) {
				w[o]   = w[o] || function () {
					(w[o].q = w[o].q || []).push(arguments);
				};
				mpd    = d.createElement('div');
				mpd.id = 'widget_' + o;
				d.body.appendChild(mpd);
				js = d.createElement(s), fjs = d.getElementById('widget_' + o);
				js.id    = o;
				js.src   = f;
				js.async = 1;
				fjs.parentNode.insertBefore(js, fjs);
			}(window, document, 'script', 'lead2team', 'https://online.lead2team.com/widget/widget.js'));
			lead2team('init', { apiKey : 'Txje2WJukBwTsaCmD320hWtWXZbPmwTn' });
			lead2team('lead2team', { teams : '842', locations : '', profiles : '', zindex : '1000' });
		}
	}
};

export default motorPriveMixin;
