const c2cSoautoMixin = {
  methods: {
    setC2C() {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = "https://apt01.bytalk.com/9295bf3ba8";
      script.async;

      document.head.appendChild(script);
    },
  },
};

export default c2cSoautoMixin;
